import React from "react"

import TermsofUse from "../components/TermsofUse/Home"
import SEO from "../components/Base/SEO"
import InnerPageBanner from "../components/Shared/InnerPageBanner"
import Layout from "../components/Base/Layout"

export default function () {
  return (
    <Layout>
      <SEO title={"sheer analytics"} />
      <InnerPageBanner headingText={"Terms of Use"} linkText={"Terms of Use"} />
      <TermsofUse />
    </Layout>
  )
}