import React from "react"

export default function() {
  return (
    <section style={{margin: 0}}>
        <div
          className="col-md-12"
          style={{background: "#58b0da29",color: "#443636",paddingTop: "3em",paddingBottom: "2em"}}
        >
          <div style={{width: "100%"}}>
              <p>
              Welcome to Sheer Analytics and Insights! By accessing our website and using our services, you agree
to be bound by these terms of use. If you do not agree with any part of these terms of use, you may
not use our services.            
              </p>
              <br />
              <h1>Use of Information:</h1>
              <p>All information provided by Sheer Analytics and Insights is for informational purposes only. We do
not guarantee the accuracy, completeness, or reliability of any information provided. You are solely
responsible for any decisions you make based on the information provided by us. We shall not be
liable for any damages arising from the use of our information.</p>
            <h1>Intellectual Property:</h1>
            <p>All content on our website, including text, graphics, logos, and images, is the property of Sheer
Analytics and Insights and is protected by copyright laws. You may not use any of our content
without our express written consent.</p>
            <h1>User Conduct:</h1>
<p>You may not use our website or services to:
<div>• Post, upload, or transmit any material that is defamatory, obscene, or unlawful.</div>
<div>• Engage in any activity that violates any law or regulation.</div>
<div>• Impersonate any person or entity or falsely represent your affiliation with any person or
entity.</div></p>
<h1>Termination:</h1>
<p>We reserve the right to terminate your access to our website and services at any time,
without notice, for any reason.</p>
<h1>Indemnification:</h1>
<p>You agree to indemnify and hold harmless Sheer Analytics and Insights, its affiliates, officers,
directors, employees, and agents, from and against any claims, liabilities, damages, losses,
and expenses, including reasonable attorney&#39;s fees, arising out of or in connection with your
use of our website or services.</p>
<h1>Governing Law:</h1>
<p>These terms of use shall be governed by and construed in accordance with the laws of the state of
West Bengal, India.</p>
<h1>Changes to Terms of Use:</h1>
<p>We reserve the right to modify or update these terms of use at any time without prior notice. Your
continued use of our website and services after any such changes constitutes your acceptance of the
new terms of use.</p>
<br/>
<p>Thank you for using Sheer Analytics and Insights!</p>
          </div>
        </div>
    </section>
  )
}
